.v-date-panel {
  padding: 2px;
  min-width: 200px;
  user-select: none;
}
.v-date-panel > .head {
  padding: 6px;
  display: flex;
  align-items: center;
  line-height: initial;
  justify-content: space-between;
}
.v-date-panel > .head > .prev,
.v-date-panel > .head > .next {
  display: flex;
}
.v-date-panel > .head > .prev > .ss-pc,
.v-date-panel > .head > .next > .ss-pc {
  margin: 2px;
  cursor: pointer;
  border-radius: 4px;
}
.v-date-panel > .head > .prev > .ss-pc:hover,
.v-date-panel > .head > .next > .ss-pc:hover {
  color: #3e946a;
}
.v-date-panel > .body {
  width: 100%;
  display: table;
}
.v-date-panel > .body > .week {
  display: table-row;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner:hover > .content {
  background-color: #f0f0f0;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.tomonth > .content {
  color: #666666;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.today > .content {
  color: #3e946a;
  border-color: #3e946a;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.included.today > .content {
  color: #3e946a;
  border-color: #3e946a;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.included.tomonth > .content {
  color: #333333;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.included > .content {
  color: #999999;
  background-color: rgba(65, 184, 131, 0.2);
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.selected.today > .content,
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.begin.today > .content,
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.end.today > .content {
  box-shadow: 0px 0px 0px 1px #ffffff inset;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.selected > .content,
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.begin > .content,
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.end > .content {
  color: #ffffff;
  background-color: #3e946a;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner.disabled > .content {
  cursor: no-drop;
  color: #bbbbbb;
  background-color: #f0f0f0;
}
.v-date-panel > .body > .week:not(:first-child) > .day > .inner > .content {
  cursor: pointer;
  color: #bbbbbb;
  border-radius: 4px;
  transition: 300ms;
}
.v-date-panel > .body > .week > .day {
  text-align: center;
  border-radius: 4px;
  display: table-cell;
}
.v-date-panel .v-scaler {
  margin: 2px;
}
.v-date-panel .v-scaler > .content {
  display: flex;
  align-items: center;
  justify-content: center;
}
