@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_ylj64lcjg1.eot");
  /* IE9*/
  src: url("//at.alicdn.com/t/webfont_ylj64lcjg1.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("//at.alicdn.com/t/webfont_ylj64lcjg1.woff2") format("woff2"), url("//at.alicdn.com/t/webfont_ylj64lcjg1.woff") format("woff"), /* chrome、firefox */ url("//at.alicdn.com/t/webfont_ylj64lcjg1.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url("//at.alicdn.com/t/webfont_ylj64lcjg1.svg#NotoSansHans-DemiLight") format("svg");
  /* iOS 4.1- */
}
html {
  height: 100%;
}
html > body {
  margin: 0px;
  height: 100%;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
  /*字体大小*/
  /*字体排列*/
  /*字体排列*/
  /*字号*/
}
html > body * {
  box-sizing: border-box;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  font-family: "webfont" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*滚动条*/
}
html > body *::selection {
  color: #ffffff;
  background-color: #3e946a;
}
html > body *::-webkit-scrollbar {
  display: none;
}
html > body *::placeholder,
html > body *[placeholder]:empty::before {
  color: #999999;
  content: attr(placeholder);
}
html > body *.v-scrollbar {
  overflow: auto;
  position: relative;
}
html > body *.v-scrollbar:hover > .v-scrollbar-horizontal,
html > body *.v-scrollbar:active > .v-scrollbar-horizontal,
html > body *.v-scrollbar:hover > .v-scrollbar-vertical,
html > body *.v-scrollbar:active > .v-scrollbar-vertical {
  background-color: rgba(0, 0, 0, 0.3);
  transition-delay: 0ms;
}
html > body *.v-scrollbar > .v-scrollbar-horizontal,
html > body *.v-scrollbar > .v-scrollbar-vertical {
  width: 10%;
  z-index: 1;
  margin: 3px;
  display: initial;
  user-select: none;
  position: absolute;
  border-radius: 1.5px;
  -webkit-touch-callout: none;
  background-color: transparent;
  transition-delay: 1000ms;
  transition-duration: 300ms;
}
html > body *.v-scrollbar > .v-scrollbar-horizontal:hover,
html > body *.v-scrollbar > .v-scrollbar-vertical:hover,
html > body *.v-scrollbar > .v-scrollbar-horizontal:active,
html > body *.v-scrollbar > .v-scrollbar-vertical:active {
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 300ms;
}
html > body *.v-scrollbar > .v-scrollbar-horizontal {
  left: 0px;
  bottom: 0px;
  height: 3px;
  min-width: 9px;
  transition-property: width, transform, background-color;
}
html > body *.v-scrollbar > .v-scrollbar-vertical {
  top: 0px;
  right: 0px;
  width: 3px;
  min-height: 9px;
  transition-property: height, transform, background-color;
}
html > body small {
  font-size: 12px;
}
html > body .text-left {
  text-align: left;
}
html > body .text-center {
  text-align: center;
}
html > body .text-right {
  text-align: right;
}
html > body .text-lowercase {
  text-transform: lowercase;
}
html > body .text-uppercase {
  text-transform: uppercase;
}
html > body .text-capitalize {
  text-transform: capitalize;
}
html > body .f10 {
  font-size: 10px;
}
html > body .f11 {
  font-size: 11px;
}
html > body .f12 {
  font-size: 12px;
}
html > body .f13 {
  font-size: 13px;
}
html > body .f14 {
  font-size: 14px;
}
html > body .f15 {
  font-size: 15px;
}
html > body .f16 {
  font-size: 16px;
}
html > body .f17 {
  font-size: 17px;
}
html > body .f18 {
  font-size: 18px;
}
html > body .f19 {
  font-size: 19px;
}
html > body .f20 {
  font-size: 20px;
}
html > body .f22 {
  font-size: 22px;
}
html > body .f24 {
  font-size: 24px;
}
html > body .f26 {
  font-size: 26px;
}
html > body .f28 {
  font-size: 28px;
}
html > body .f30 {
  font-size: 30px;
}
html > body .f32 {
  font-size: 32px;
}
html > body .f34 {
  font-size: 34px;
}
html > body .f36 {
  font-size: 36px;
}
html > body [required]::before {
  content: '*';
  color: #f5222d;
}
html > body [ellipsis] {
  overflow: hidden;
}
html > body [ellipsis][ellipsis=""] {
  white-space: nowrap;
  text-overflow: ellipsis;
}
html > body [ellipsis]:not([ellipsis=""]) {
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
}
html > body [ellipsis]:not([ellipsis=""])[ellipsis="1"] {
  -webkit-line-clamp: 1;
}
html > body [ellipsis]:not([ellipsis=""])[ellipsis="2"] {
  -webkit-line-clamp: 2;
}
html > body [ellipsis]:not([ellipsis=""])[ellipsis="3"] {
  -webkit-line-clamp: 3;
}
html > body [ellipsis]:not([ellipsis=""])[ellipsis="4"] {
  -webkit-line-clamp: 4;
}
html > body [ellipsis]:not([ellipsis=""])[ellipsis="5"] {
  -webkit-line-clamp: 5;
}
html > body [ellipsis]:not([ellipsis=""])[ellipsis="6"] {
  -webkit-line-clamp: 6;
}
html > body [disabled] {
  cursor: no-drop;
  filter: grayscale(1) brightness(0.9);
}
html > body i.ss-pc {
  font-size: inherit;
  font-style: normal;
}
html > body .link {
  color: #41b883;
  cursor: pointer;
}
html > body a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
html > body label[for] {
  user-select: none;
}
html > body pre {
  top: 40px;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  padding: 10px;
  overflow: auto;
  position: fixed;
  max-width: 500px;
  color: #ffffff;
  border: 1px solid #999999;
  background-color: rgba(0, 0, 0, 0.6);
}
html > body pre:hover {
  width: auto;
  height: auto;
}
html > body .form > .item {
  margin: 10px;
  display: flex;
  line-height: 24px;
  align-items: flex-start;
}
html > body .form > .item > label {
  width: 80px;
  margin: 3px 10px;
  text-align: right;
  display: inline-block;
}
html > body .form > .item > .content {
  flex: 1;
  width: 0px;
}
