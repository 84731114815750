.v-pagination {
  display: flex;
  margin: 24px 0px;
  align-items: center;
  justify-content: space-between;
}
.v-pagination > .btns {
  user-select: none;
}
.v-pagination > .btns > .btn {
  cursor: pointer;
  min-width: 24px;
  color: #666666;
  line-height: 24px;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  transition: 300ms;
}
.v-pagination > .btns > .btn:hover {
  color: #349e69;
  border-color: #349e69;
}
.v-pagination > .btns > .btn.go {
  margin: 0px 1px;
}
.v-pagination > .btns > .btn.go.active {
  color: #ffffff;
  border-color: #349e69;
  background-color: #349e69;
}
.v-pagination[sticky] {
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: sticky;
  background-color: #ffffff;
}
