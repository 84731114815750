




























































































































































.v-date-panel {
  padding: 2px;
  min-width: 200px;
  user-select: none;
  > .head {
    padding: 6px;
    display: flex;
    align-items: center;
    line-height: initial;
    justify-content: space-between;
    > .prev,
    > .next {
      display: flex;
      > .ss-pc {
        margin: 2px;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
          color: #3e946a;
        }
      }
    }
  }
  > .body {
    width: 100%;
    display: table;
    > .week {
      display: table-row;
      &:not(:first-child) {
        > .day {
          > .inner {
            &:hover {
              > .content {
                background-color: #f0f0f0;
              }
            }
            &.tomonth {
              > .content {
                color: #666666;
              }
            }
            &.today {
              > .content {
                color: #3e946a;
                border-color: #3e946a;
                // box-shadow: 0px 0px 0px 1px #3e946a inset;
              }
            }
            &.included {
              &.today {
                > .content {
                  color: #3e946a;
                  border-color: #3e946a;
                }
              }
              &.tomonth {
                > .content {
                  color: #333333;
                }
              }
              > .content {
                color: #999999;
                background-color: rgba(65, 184, 131, 0.2);
              }
            }
            &.selected,
            &.begin,
            &.end {
              &.today {
                > .content {
                  box-shadow: 0px 0px 0px 1px #ffffff inset;
                }
              }
              > .content {
                color: #ffffff;
                background-color: #3e946a;
              }
            }
            &.disabled {
              > .content {
                cursor: no-drop;
                color: #bbbbbb;
                background-color: #f0f0f0;
              }
            }
            > .content {
              cursor: pointer;
              color: #bbbbbb;
              border-radius: 4px;
              transition: 300ms;
            }
          }
        }
      }
      > .day {
        text-align: center;
        border-radius: 4px;
        display: table-cell;
      }
    }
  }
  .v-scaler {
    margin: 2px;
    > .content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
