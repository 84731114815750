.v-button {
  outline: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0px 15px;
  user-select: none;
  line-height: 28px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  transition: 200ms;
}
.v-button:not(:disabled):hover {
  filter: brightness(0.95);
}
.v-button:not(:disabled):active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) inset;
}
.v-button:not(:disabled)[line]:hover {
  filter: brightness(0.95);
}
.v-button:not(:disabled)[line]:active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
}
.v-button[type="primary"] {
  color: #ffffff;
  border-color: #41ac76;
  background-color: #41ac76;
}
.v-button[type="primary"][line] {
  color: #41ac76;
  background-color: #ffffff;
}
.v-button[type="info"] {
  color: #ffffff;
  border-color: #1890ff;
  background-color: #1890ff;
}
.v-button[type="info"][line] {
  color: #1890ff;
  background-color: #ffffff;
}
.v-button[type="warning"] {
  color: #ffffff;
  border-color: #faad14;
  background-color: #faad14;
}
.v-button[type="warning"][line] {
  color: #faad14;
  background-color: #ffffff;
}
.v-button[type="danger"] {
  color: #ffffff;
  border-color: #f5222d;
  background-color: #f5222d;
}
.v-button[type="danger"][line] {
  color: #f5222d;
  background-color: #ffffff;
}
.v-button[size="small"] {
  font-size: 12px;
  line-height: 22px;
  border-radius: 2px;
}
.v-button[size="large"] {
  font-size: 16px;
  line-height: 34px;
  border-radius: 6px;
}
.v-button[theme="pill"] {
  font-size: 12px;
  padding: 0px 10px;
  line-height: 22px;
  border-radius: 12px;
}
