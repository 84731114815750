.v-time-panel {
  user-select: none;
  display: inline-flex;
  flex-direction: column;
}
.v-time-panel > .labels {
  display: flex;
  border-bottom: 1px solid #dddddd;
}
.v-time-panel > .labels > .label {
  flex: 1;
  font-size: 12px;
  color: #999999;
  padding: 5px 10px;
  text-align: center;
}
.v-time-panel > .groups {
  flex: 1;
  height: 0px;
  display: flex;
}
.v-time-panel > .groups > .group {
  width: 50px;
  position: relative;
  text-align: center;
}
.v-time-panel > .groups > .group:not(:first-child):not(:last-child) {
  border-style: solid;
  border-width: 0px 1px;
  border-color: #dddddd;
}
.v-time-panel > .groups > .group > .item {
  cursor: pointer;
  padding: 0px 10px;
  line-height: 28px;
  transition: 200ms;
}
.v-time-panel > .groups > .group > .item:hover {
  background-color: #f0f0f0;
}
.v-time-panel > .groups > .group > .item.active {
  top: 0px;
  bottom: 0px;
  color: #ffffff;
  position: sticky;
  background-color: #349e69;
}
