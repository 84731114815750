













































































.v-input {
  width: 264px;
  font-size: 14px;
  // border-radius: 4px;
  display: inline-flex;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  transition: 200ms;
  &:not([theme]) {
    &:hover {
      border-color: #41b883;
    }
    &.focus {
      border-color: #41b883;
    }
    > input {
      &:first-child {
        padding-left: 6px;
      }
      &:last-child:not([type="number"]) {
        padding-right: 6px;
      }
    }
  }
  &:not([size]) {
    line-height: 30px;
  }
  &[size="small"] {
    line-height: 20px;
  }
  > input {
    flex: 1;
    width: 0px;
    border: none;
    padding: 0em;
    outline: none;
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    background-color: transparent;
    &[type="password"] {
      letter-spacing: 4px;
      &::placeholder {
        letter-spacing: initial;
      }
    }
    &[type="search"] {
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }
}
