























































.v-time-panel {
  user-select: none;
  display: inline-flex;
  flex-direction: column;
  > .labels {
    display: flex;
    border-bottom: 1px solid #dddddd;
    > .label {
      flex: 1;
      font-size: 12px;
      color: #999999;
      padding: 5px 10px;
      text-align: center;
    }
  }
  > .groups {
    flex: 1;
    height: 0px;
    display: flex;
    > .group {
      width: 50px;
      position: relative;
      text-align: center;
      &:not(:first-child):not(:last-child) {
        border-style: solid;
        border-width: 0px 1px;
        border-color: #dddddd;
      }
      > .item {
        cursor: pointer;
        padding: 0px 10px;
        line-height: 28px;
        transition: 200ms;
        &:hover {
          background-color: #f0f0f0;
        }
        &.active {
          top: 0px;
          bottom: 0px;
          color: #ffffff;
          position: sticky;
          background-color: #349e69;
        }
      }
    }
  }
}
