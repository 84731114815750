i {
  &.ss-pc {
    font-size: inherit;
    font-style: normal;
  }
}

.link {
  color: @primary;
  cursor: pointer;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

label {
  &[for] {
    // cursor: pointer;
    user-select: none;
  }
}

// input {
//   width: 180px;
//   outline: none;
//   font-size: 14px;
//   padding: 0px 6px;
//   line-height: 28px;
//   border-radius: 4px;
//   border: 1px solid #cccccc;
//   background-color: #ffffff;
//   transition: 200ms;

//   &:not(:disabled) {
//     &:focus {
//       border-color: @primary;
//     }
//   }

//   &[type="password"] {
//     letter-spacing: 3px;

//     &::placeholder {
//       letter-spacing: initial;
//     }
//   }

//   &[type="number"] {
//     padding-right: 0px;

//     &::-webkit-outer-spin-button,
//     &::-webkit-inner-spin-button {
//       cursor: pointer;
//       // -webkit-appearance: none;
//     }
//   }

//   &[type="radio"] {
//     margin: 2px;
//     width: 16px;
//     cursor: pointer;
//     font-weight: bold;

//     &:before {
//       width: 16px;
//       height: 16px;
//       display: inline-block;
//       // flex: 1;
//       // width: 0px;
//       content: '●';
//       color: #ffffff;
//       border-radius: 50%;
//       box-sizing: border-box;
//       border: 2px solid #dddddd;
//       background-color: #ffffff;
//       transition: 200ms;
//     }

//     &:hover {
//       &:before {
//         border-color: @primary;
//       }
//     }

//     &:checked {
//       &:before {
//         color: @primary;
//         border-color: @primary;
//       }
//     }
//   }

//   &[type="checkbox"] {
//     margin: 2px;
//     width: 16px;
//     cursor: pointer;
//     font-weight: bold;

//     &:before {
//       width: 16px;
//       height: 16px;
//       display: inline-block;
//       // flex: 1;
//       // width: 0px;
//       content: '✓';
//       color: #ffffff;
//       border-radius: 4px;
//       box-sizing: border-box;
//       border: 2px solid #dddddd;
//       background-color: #ffffff;
//       transition: 200ms;
//     }

//     &:hover {
//       &:before {
//         border-color: @primary;
//       }
//     }

//     &:checked {
//       &:before {
//         border-color: @primary;
//         background-color: @primary;
//       }
//     }
//   }

//   &[size="small"] {
//     line-height: 20px;
//   }
// }

// textarea {
//   outline: none;
//   font-size: 14px;
//   padding: 4px 6px;
//   min-width: 180px;
//   min-height: 30px;
//   max-width: 100%;
//   line-height: 20px;
//   border-radius: 4px;
//   border: 1px solid #cccccc;
//   background-color: #ffffff;
// }

// select {
//   cursor: pointer;
// }

// button {
//   outline: none;
//   cursor: pointer;
//   font-size: 14px;
//   padding: 0px 15px;
//   user-select: none;
//   line-height: 28px;
//   border-radius: 4px;
//   border: 1px solid #cccccc;
//   background-color: #ffffff;
//   transition: 200ms;

//   &:not(:disabled) {
//     &:hover {
//       filter: brightness(0.9);
//     }

//     &:active {
//       box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) inset;
//     }

//     &[line] {
//       &:hover {
//         filter: brightness(0.95);
//       }

//       &:active {
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
//       }
//     }
//   }

//   &[type="primary"] {
//     color: #ffffff;
//     border-color: @primary;
//     background-color: @primary;

//     &[line] {
//       color: @primary;
//       background-color: #ffffff;
//     }
//   }

//   &[type="info"] {
//     color: #ffffff;
//     border-color: @info;
//     background-color: @info;

//     &[line] {
//       color: @info;
//       background-color: #ffffff;
//     }
//   }

//   &[type="warning"] {
//     color: #ffffff;
//     border-color: @warning;
//     background-color: @warning;

//     &[line] {
//       color: @warning;
//       background-color: #ffffff;
//     }
//   }

//   &[type="danger"] {
//     color: #ffffff;
//     border-color: @danger;
//     background-color: @danger;

//     &[line] {
//       color: @danger;
//       background-color: #ffffff;
//     }
//   }

//   &[size="small"] {
//     font-size: 12px;
//     line-height: 22px;
//     border-radius: 2px;
//   }

//   &[size="large"] {
//     font-size: 16px;
//     line-height: 34px;
//     border-radius: 6px;
//   }

//   &[theme="pill"] {
//     padding: 0px 10px;
//     line-height: 22px;
//     border-radius: 12px;
//   }
// }

// table {
//   width: 100%;
//   border-collapse: collapse;

//   >thead {
//     border: 1px solid #dddddd;
//     background-color: #f0f0f0;

//     >th {
//       padding: 5px;
//       text-align: left;
//     }
//   }

//   >tbody {
//     >tr {
//       border: 1px solid #dddddd;

//       >td {
//         padding: 5px;
//       }
//     }
//   }
// }