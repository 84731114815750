















































.v-textarea {
  width: 304px;
  height: 32px;
  display: inline-flex;
  box-sizing: border-box;
  background-color: #ffffff;
  > textarea {
    flex: 1;
    resize: none;
    outline: none;
    font-size: 14px;
    padding: 6px 6px;
    line-height: 18px;
    border-radius: 4px;
    font-family: inherit;
    border: 1px solid #cccccc;
    background-color: transparent;
    transition: border-color 300ms;
    &:focus {
      position: relative;
      border-color: #3e946a;
    }
  }
}
