










.v-app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
