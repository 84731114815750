.form {
  >.item {
    margin: 10px;
    display: flex;
    line-height: 24px;
    align-items: flex-start;

    >label {
      width: 80px;
      margin: 3px 10px;
      text-align: right;
      display: inline-block;
    }

    >.content {
      flex: 1;
      width: 0px;
      // margin: 3px 0px;
    }
  }
}