


































































































































.v-popover {
  position: relative;
  display: inline-flex;
  &.active {
    > .content {
      z-index: 2;
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
  }
  > .content {
    top: 100%;
    z-index: 1;
    opacity: 0;
    overflow: auto;
    min-width: 100%;
    margin-top: 5px;
    max-height: 300px;
    position: absolute;
    visibility: hidden;
    border-radius: 4px;
    line-height: initial;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, -50%, 0) scale3d(1, 0, 1);
    transition: 300ms;
    &.nodata::after {
      display: block;
      color: #999999;
      padding: 5px 10px;
      content: "暂无数据";
    }
  }
}
