

































































































































.v-cascader {
  width: 264px;
  display: inline-flex;
  flex-direction: column;
  &.active {
    > .selected {
      border-color: #3e946a;
      &::after {
        border-top-color: #3e946a;
        transform: translate3d(-8px, 12px, 0) rotateZ(180deg);
      }
    }
  }
  &.loading {
    > .content {
      &:before,
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  > .selected {
    outline: none;
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    min-height: 32px;
    line-height: 24px;
    position: relative;
    border-radius: 4px;
    padding: 3px 24px 3px 6px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    transition: border 300ms;
    &:hover,
    &:focus {
      border-color: #3e946a;
    }
    &[context]:empty::before {
      color: #333333;
      content: attr(context);
    }
    &::after {
      top: 0px;
      right: 0px;
      width: 0px;
      height: 0px;
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 6px 6px 0px 6px;
      border-color: #999999 transparent transparent transparent;
      transform: translate3d(-8px, 13px, 0) rotateZ(0deg);
      transition: transform 300ms;
    }
  }
  > .content {
    display: flex;
    min-width: auto;
    overflow: hidden;
    &:before {
      z-index: 1;
      opacity: 0;
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      visibility: hidden;
      background-color: rgba(255, 255, 255, 0.6);
      transition: 200ms;
    }
    &:after {
      top: 50%;
      left: 50%;
      z-index: 2;
      opacity: 0;
      content: "";
      width: 30px;
      height: 30px;
      display: block;
      border-width: 3px;
      position: absolute;
      visibility: hidden;
      border-radius: 50%;
      border-style: solid;
      border-color: #3e946a transparent #3e946a transparent;
      transform: translate3d(-50%, -50%, 0);
      transition: 200ms;
      animation: loading 600ms linear infinite;
    }
    > .options {
      max-height: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dddddd;
      }
      > .option {
        margin: 2px 0px;
        cursor: pointer;
        min-width: 100px;
        padding: 5px 10px;
        transition: 300ms;
        user-select: none;
        white-space: nowrap;
        &:hover {
          background-color: #f0f0f0;
        }
        &.selected {
          top: 0px;
          bottom: 0px;
          color: #ffffff;
          position: sticky;
          background-color: #3e946a;
        }
      }
    }
  }
}
