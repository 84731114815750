



















































































.v-datetime-picker {
  min-width: 264px;
  display: inline-flex;
  flex-direction: column;
  &.active {
    > .selected {
      border-color: #3e946a;
    }
  }
  > .selected {
    flex: 1;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    padding: 2px 6px;
    min-height: 32px;
    text-align: left;
    user-select: none;
    line-height: 24px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    transition: border 300ms;
    &:hover,
    &:focus {
      border-color: #3e946a;
    }
  }
  > .content {
    > .body {
      height: 236px;
      display: flex;
      > .v-date-panel {
        padding: 4px;
      }
      > .v-time-panel {
        border-left: 1px solid #dddddd;
      }
    }
    > .foot {
      padding: 5px;
      text-align: right;
      border-top: 1px solid #dddddd;
    }
  }
}
