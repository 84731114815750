/*字体大小*/
small {
  font-size: 12px;
}

/*字体排列*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*字体排列*/
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/*字号*/
.f10 {
  font-size: 10px;
}

.f11 {
  font-size: 11px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f17 {
  font-size: 17px;
}

.f18 {
  font-size: 18px;
}

.f19 {
  font-size: 19px;
}

.f20 {
  font-size: 20px;
}

.f22 {
  font-size: 22px;
}

.f24 {
  font-size: 24px;
}

.f26 {
  font-size: 26px;
}

.f28 {
  font-size: 28px;
}

.f30 {
  font-size: 30px;
}

.f32 {
  font-size: 32px;
}

.f34 {
  font-size: 34px;
}

.f36 {
  font-size: 36px;
}