.v-date-range-picker {
  width: 264px;
  display: inline-flex;
  flex-direction: column;
}
.v-date-range-picker.active > .selected {
  border-color: #3e946a;
}
.v-date-range-picker > .selected {
  flex: 1;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  min-height: 32px;
  text-align: left;
  user-select: none;
  line-height: 24px;
  position: relative;
  border-radius: 4px;
  padding: 2px 6px 2px 6px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  transition: border 300ms;
}
.v-date-range-picker > .selected:hover,
.v-date-range-picker > .selected:focus {
  border-color: #3e946a;
}
