.v-upload-image {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 2px dashed #cccccc;
  transition: 300ms;
}
.v-upload-image:hover {
  border-color: #3e946a;
}
.v-upload-image:hover > .inner::before,
.v-upload-image:hover > .inner::after {
  border-color: #3e946a;
}
.v-upload-image:-webkit-user-drag {
  background-color: red;
}
.v-upload-image.draging {
  border-color: #3e946a;
}
.v-upload-image.draging > .inner::before,
.v-upload-image.draging > .inner::after {
  border-color: #3e946a;
}
.v-upload-image.draging > .inner::before {
  height: 20px;
  border-width: 2px 0 2px 0;
}
.v-upload-image.draging > .inner::after {
  width: 20px;
  border-width: 0 2px 0 2px;
}
.v-upload-image > .inner {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
}
.v-upload-image > .inner::before,
.v-upload-image > .inner::after {
  top: 50%;
  left: 50%;
  content: "";
  display: block;
  position: absolute;
  border-radius: 1px;
  border-style: solid;
  border-color: #cccccc;
  transform: translate3d(-50%, -50%, 0);
  transition: 300ms;
}
.v-upload-image > .inner::before {
  width: 30px;
  height: 0px;
  border-width: 1px 0 1px 0;
}
.v-upload-image > .inner::after {
  width: 0px;
  height: 30px;
  border-width: 0 1px 0 1px;
}
.v-upload-image > img {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: inherit;
  background-color: inherit;
}
.v-upload-image > img[src=""] {
  display: none;
}
