
















































































.v-pagination {
  display: flex;
  display: flex;
  margin: 24px 0px;
  align-items: center;
  justify-content: space-between;
  > .btns {
    user-select: none;
    > .btn {
      cursor: pointer;
      min-width: 24px;
      color: #666666;
      line-height: 24px;
      border-radius: 2px;
      text-align: center;
      display: inline-block;
      border: 1px solid #dddddd;
      background-color: #ffffff;
      transition: 300ms;
      &:hover {
        color: #349e69;
        border-color: #349e69;
      }
      &.go {
        margin: 0px 1px;
        &.active {
          color: #ffffff;
          border-color: #349e69;
          background-color: #349e69;
        }
      }
    }
  }
  &[sticky] {
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: sticky;
    // border: 1px solid #dddddd;
    background-color: #ffffff;
    // background-color: rgba(255, 255, 255, 0.6);
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
}
