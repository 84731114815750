














































.v-radio {
  border: none;
  padding: 0px;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  text-align: inherit;
  white-space: nowrap;
  line-height: inherit;
  background-color: transparent;
  &:not(:disabled) {
    &:hover {
      > .inner {
        border-color: #3e946a;
      }
    }
    &.checked {
      > .inner {
        border-color: #3e946a;
        background-color: #3e946a;
        box-shadow: 0 0 0 2px #ffffff inset;
      }
    }
  }
  > .inner {
    width: 16px;
    height: 16px;
    padding: 6px;
    outline: none;
    cursor: inherit;
    border-radius: 50%;
    line-height: inherit;
    border: 2px solid #cccccc;
    background-color: #ffffff;
    transition: 300ms;
  }
  > label {
    cursor: inherit;
  }
}
