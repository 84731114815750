

































.v-upload {
  padding: 0px;
  outline: none;
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}
