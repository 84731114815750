@primary: #41b883;
@danger: #f5222d;
@warning: #faad14;
@info: #1890ff;

@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_ylj64lcjg1.eot");
  /* IE9*/
  src: url("//at.alicdn.com/t/webfont_ylj64lcjg1.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("//at.alicdn.com/t/webfont_ylj64lcjg1.woff2") format("woff2"),
    url("//at.alicdn.com/t/webfont_ylj64lcjg1.woff") format("woff"),
    /* chrome、firefox */
    url("//at.alicdn.com/t/webfont_ylj64lcjg1.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("//at.alicdn.com/t/webfont_ylj64lcjg1.svg#NotoSansHans-DemiLight") format("svg");
  /* iOS 4.1- */
}

html {
  height: 100%;

  >body {
    margin: 0px;
    height: 100%;
    font-size: 14px;
    -webkit-overflow-scrolling: touch;

    * {
      box-sizing: border-box;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      font-family: "webfont" !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &::selection {
        color: #ffffff;
        background-color: #3e946a;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &::placeholder,
      &[placeholder]:empty::before {
        color: #999999;
        content: attr(placeholder);
      }

      /*滚动条*/
      &.v-scrollbar {
        overflow: auto;
        position: relative;

        &:hover,
        &:active {

          >.v-scrollbar-horizontal,
          >.v-scrollbar-vertical {
            background-color: rgba(0,
                0,
                0,
                0.3); // transition: background-color 300ms;
            // transition-duration: 300ms;
            // transition-property: background-color;
            transition-delay: 0ms;
          }

          >.v-scrollbar-horizontal {
            // transition-property: width, background-color;
          }

          >.v-scrollbar-vertical {
            // transition-property: height, background-color;
          }
        }

        >.v-scrollbar-horizontal,
        >.v-scrollbar-vertical {
          width: 10%;
          z-index: 1;
          margin: 3px;
          display: initial;
          user-select: none;
          position: absolute;
          border-radius: 1.5px;
          -webkit-touch-callout: none;
          background-color: transparent;
          transition-delay: 1000ms;
          transition-duration: 300ms;

          &:hover,
          &:active {
            background-color: rgba(0, 0, 0, 0.4);
            transition: background-color 300ms;
          }
        }

        >.v-scrollbar-horizontal {
          left: 0px;
          bottom: 0px;
          height: 3px;
          min-width: 9px;
          transition-property: width, transform, background-color;
        }

        >.v-scrollbar-vertical {
          top: 0px;
          right: 0px;
          width: 3px;
          min-height: 9px;
          transition-property: height, transform, background-color;
        }
      }
    }

    @import "typeset.less";
    @import "attribute.less";
    @import "control.less";
    @import "element.less";
    @import "layout.less";
  }
}