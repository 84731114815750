.v-scaler {
  position: relative;
}
.v-scaler > .content {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  position: absolute;
}
