.v-message-group {
  top: 0px;
  left: 50%;
  z-index: 1;
  overflow: auto;
  position: fixed;
  margin: 0px auto;
  max-height: 100%;
  padding: 0px 55px;
  transform: translate3d(-50%, 0, 0);
}
.v-message-group > .message {
  opacity: 1;
  margin: 10px;
  position: relative;
  visibility: visible;
  transition: 300ms;
}
.v-message-group > .message::selection {
  color: #ffffff;
}
.v-message-group > .message:hover > .icon-clear {
  opacity: 1;
  visibility: visible;
}
.v-message-group > .message > .copied {
  top: 5px;
  left: 100%;
  opacity: 0;
  font-size: 12px;
  content: "已复制";
  color: #ffffff;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  border-radius: 4px;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate3d(-10px, 0, 0) scale3d(0.5, 0.5, 1);
  transition: 300ms;
}
.v-message-group > .message > .copied::before {
  top: 0px;
  left: 0px;
  z-index: 1;
  content: "";
  position: absolute;
  border-style: solid;
  display: inline-block;
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;
  transform: translate3d(-5px, 7px, 0);
}
.v-message-group > .message > .copied.show {
  opacity: 1;
  visibility: visible;
  transform: translate3d(10px, 0, 0) scale3d(1, 1, 1);
}
.v-message-group > .message.info > .content {
  color: #1890ff;
  border-color: #1890ff;
}
.v-message-group > .message.info > .content > .context::selection {
  background-color: #1890ff;
}
.v-message-group > .message.success > .content {
  color: #52c41a;
  border-color: #52c41a;
}
.v-message-group > .message.success > .content > .context::selection {
  background-color: #52c41a;
}
.v-message-group > .message.warn > .content {
  color: #fa8c16;
  border-color: #fa8c16;
}
.v-message-group > .message.warn > .content > .context::selection {
  background-color: #fa8c16;
}
.v-message-group > .message.error > .content {
  color: #f5222d;
  border-color: #f5222d;
}
.v-message-group > .message.error > .content > .context::selection {
  background-color: #f5222d;
}
.v-message-group > .message.message-enter,
.v-message-group > .message.message-leave-to {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -50%, 0) scale3d(0.6, 0.6, 1);
}
.v-message-group > .message > .content {
  display: flex;
  overflow: auto;
  min-width: 200px;
  max-width: 600px;
  max-height: 300px;
  padding: 5px 15px;
  line-height: 20px;
  white-space: pre-wrap;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0 0 8px rgba(127, 127, 127, 0.5);
}
.v-message-group > .message > .content > i {
  font-size: 16px;
  margin-right: 6px;
}
.v-message-group > .message > .icon-clear {
  top: 7px;
  right: 7px;
  opacity: 0;
  width: 18px;
  height: 18px;
  line-height: 1;
  cursor: pointer;
  font-size: 18px;
  color: #7a6d6d;
  position: absolute;
  visibility: hidden;
  border-radius: 50%;
  background-color: #ffffff;
  transition: 200ms;
}
