.v-input {
  width: 264px;
  font-size: 14px;
  display: inline-flex;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  transition: 200ms;
}
.v-input:not([theme]):hover {
  border-color: #41b883;
}
.v-input:not([theme]).focus {
  border-color: #41b883;
}
.v-input:not([theme]) > input:first-child {
  padding-left: 6px;
}
.v-input:not([theme]) > input:last-child:not([type="number"]) {
  padding-right: 6px;
}
.v-input:not([size]) {
  line-height: 30px;
}
.v-input[size="small"] {
  line-height: 20px;
}
.v-input > input {
  flex: 1;
  width: 0px;
  border: none;
  padding: 0em;
  outline: none;
  font-size: inherit;
  height: inherit;
  line-height: inherit;
  background-color: transparent;
}
.v-input > input[type="password"] {
  letter-spacing: 4px;
}
.v-input > input[type="password"]::placeholder {
  letter-spacing: initial;
}
.v-input > input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
