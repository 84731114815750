




































































.v-date-range-panel {
  display: flex;
  > .v-date-panel {
    &.begin {
      border-right: 1px solid #dddddd;
    }
  }
}
