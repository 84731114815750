pre {
  top: 40px;
  // left: 100%;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  padding: 10px;
  overflow: auto;
  position: fixed;
  // min-width: 200px;
  max-width: 500px;
  color: #ffffff;
  border: 1px solid #999999;
  background-color: rgba(0, 0, 0, 0.6);

  &:hover {
    width: auto;
    height: auto;
  }
}