.v-select-option {
  cursor: pointer;
  transition: 300ms;
  user-select: none;
  white-space: nowrap;
  line-height: initial;
}
.v-select-option:hover {
  background-color: #dcf3e8;
}
.v-select-option.selected {
  top: 0px;
  bottom: 0px;
  position: sticky;
  background-color: #e8e8e8;
}
.v-select-option > .label {
  margin: 2px 0px;
  padding: 5px 10px;
}
