[required] {
  &::before {
    content: '*';
    color: @danger;
  }
}

[ellipsis] {
  overflow: hidden;

  &[ellipsis=""] {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:not([ellipsis=""]) {
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;

    &[ellipsis="1"] {
      -webkit-line-clamp: 1;
    }

    &[ellipsis="2"] {
      -webkit-line-clamp: 2;
    }

    &[ellipsis="3"] {
      -webkit-line-clamp: 3;
    }

    &[ellipsis="4"] {
      -webkit-line-clamp: 4;
    }

    &[ellipsis="5"] {
      -webkit-line-clamp: 5;
    }

    &[ellipsis="6"] {
      -webkit-line-clamp: 6;
    }
  }

}

[disabled] {
  cursor: no-drop;
  filter: grayscale(1) brightness(0.9);
}