.v-editor {
  width: 180px;
  cursor: text;
  outline: none;
  overflow: auto;
  font-size: 14px;
  padding: 3px 6px;
  min-height: 32px;
  user-select: none;
  line-height: 24px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  transition: border 300ms;
  /*此处样式为兼容此属性的Chrome系浏览器做的代码保留 (暂时无意义)*/
}
.v-editor:focus {
  border-color: #3e946a;
}
.v-editor[contenteditable="plaintext-only"] {
  -webkit-user-modify: read-write-plaintext-only;
}
