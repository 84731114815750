


















































































.v-select {
  width: 264px;
  display: inline-flex;
  flex-direction: column;
  &[multiple] {
    > .selected-options {
      padding-left: 2px;
      &:empty {
        padding-left: 5px;
      }
      > .option {
        margin: 1px;
        padding: 0px 5px;
        border-radius: 3px;
        background-color: #e8e8e8;
        > i {
          display: inline-block;
        }
      }
    }
  }
  &.active {
    > .selected-options {
      border-color: #349e69;
      &::after {
        transform: translate3d(-8px, 11px, 0) rotateZ(180deg);
      }
    }
  }
  > .selected-options {
    flex: 1;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    min-height: 30px;
    text-align: left;
    user-select: none;
    line-height: 22px;
    position: relative;
    border-radius: 4px;
    padding: 2px 24px 2px 6px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    transition: border 200ms;
    &:hover,
    &:focus {
      border-color: #349e69;
    }
    &::after {
      top: 0px;
      right: 0px;
      width: 0px;
      height: 0px;
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 6px 5px 0px 5px;
      border-color: #999999 transparent transparent transparent;
      transform: translate3d(-8px, 12px, 0) rotateZ(0deg);
      transition: transform 200ms;
    }
    > .option {
      display: inline-block;
      word-break: break-word;
      > i {
        display: none;
        cursor: pointer;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}
